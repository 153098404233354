html {
	background-color: #26282c;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
	transform: translate(12px, 10px) scale(1) !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 9px 12px 12px !important;
}

.background-hero {
	background-image: url('./assets/img/Bridgette.png');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: rgb(209, 213, 219);
}

@media only screen and (max-width: 564px) {
	.background-hero {
		background-position: 60% 50%;
	}
}
