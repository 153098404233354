@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'MrEaves';
	src: url(./assets/fonts/Eaves.ttf);
}
@font-face {
	font-family: 'Miller';
	src: url(./assets/fonts/MillerDisplayRoman.otf);
}

@layer utilities {
	.screen-nav {
		height: 100%;
		min-height: calc(100vh - 52px);
	}
	.about-back-image {
		background-image: url(./assets/img/jpg/sean-full.jpg);
		background-repeat: no-repeat;
		background-size: cover;
	}
	@media only screen and (max-width: 564px) {
		.about-back-image {
			background-position: 45% 50%;
		}
	}
	.font-eaves {
		font-family: 'MrEaves', sans-serif;
	}
	.font-miller {
		font-family: 'Miller', serif;
	}
	.bg-yellow-light {
		background-color: hsl(42, 100%, 80%);
	}
	.text-yellow {
		color: hsl(42, 100%, 50%);
	}
	.bg-light_gray {
		background-color: hsla(214, 8%, 46 0.3);
	}
	.main-gray-10 {
		background-color: hsla(214, 8%, 46 0.3);
	}
	.main-gray-20 {
		background-color: hsl(214, 8%, 41%);
	}
	.main-gray-30 {
		background-color: hsl(214, 8%, 36%);
	}
	.main-gray-40 {
		background-color: hsl(214, 8%, 31%);
	}
	.main-gray-50 {
		background-color: hsl(214, 8%, 26%);
	}
	.main-gray-60 {
		background-color: hsl(214, 8%, 21%);
	}
	.main-gray-70 {
		background-color: hsl(214, 8%, 16%);
	}
	.main-gray-80 {
		background-color: hsl(214, 8%, 11%);
	}
	.main-gray-90 {
		background-color: hsl(214, 8%, 6%);
	}
	.bg-magenta-light {
		background-color: hsl(344, 85%, 59%) !important;
	}
	.bg-magenta-reg {
		background-color: hsl(344, 85%, 54%) !important;
	}
	.bg-magenta-dark {
		background-color: hsl(344, 85%, 39%) !important;
	}
	.magenta-hover:hover {
		background-color: hsl(344, 85%, 39%) !important;
	}
	.main-purple-reg {
		background-color: hsl(269, 95%, 37%);
	}
	.footer-border {
		border-top: 1px solid white;
		padding: 16px;
	}
	.img-upload {
		@include flexCenter(column, center);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		border: 1px solid rgb(201, 203, 207);
		border-radius: 4px;
		padding: 30px;
		cursor: pointer;
	}
	.img-upload-small {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 2px !important;
		border-radius: 4px;
		cursor: pointer;
		width: 96px !important;
		height: 96px;
		overflow: hidden;
		font-size: 0.8rem;
		position: relative;
	}
	.img-thumb {
		width: auto;
		min-width: 100%;
		height: auto;
		min-height: 100%;
		object-fit: cover;
		position: absolute;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}
	.back-silver {
		border: 1px solid white;
		transition: 0.1s ease-in-out background-color;
	}
	.back-silver:hover {
		background-color: rgb(83, 83, 83);
	}
	.hidden-input {
		position: absolute;
		width: 146px;
		height: 146px;
		opacity: 0;
		cursor: pointer;
	}
	.img-upload-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.transition-1s {
		transition: transform 0.1s ease-in-out !important;
	}
	.invest-back {
		background-color: #dc344f;
	}
	@media only screen and (max-width: 878px) {
		.footer-border {
			border-top: 1px solid white;
			padding: 16px 16px 52px;
		}
	}
}
